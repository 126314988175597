<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-container>
    <b-row>
      <b-col
        class="d-flex justify-content-center align-items-center"
        sm="12"
        md="6"
      >
        <b-img :src="require('@/assets/images/pages/coming-soon.svg')" />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-card>
          <b-card-title>
            {{ $t('newCategory') }}
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de la categoría"
                  rules="required"
                >
                  <b-form-input v-model="category.name" autocomplete="off" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group label="Tipo de unidad">
                <validation-provider
                  #default="{ errors }"
                  name="Unidad"
                  rules="required"
                >
                  <b-form-select
                    v-model="category.unit_type"
                    :options="unitTypeOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-button type="submit">
                {{ $t('add', { type: '' }) }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BRow,
  BCol,
  BButton,
  BImg,
  BForm,
  BFormGroup,
  BCard,
  BCardTitle,
  BContainer,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BImg,
    BForm,
    BFormGroup,
    BCard,
    BCardTitle,
    BContainer,
  },
  data() {
    return {
      required,
      category: {
        name: '',
        unit_type: 0,
      },
      unitTypeOptions: [
        {
          value: 'meter',
          text: this.$t('meter'),
        },
        {
          value: 'kilometer',
          text: this.$t('kilometer'),
        },
        {
          value: 'square_meters',
          text: this.$t('squareMeters'),
        },
        {
          value: 'cubic_meters',
          text: this.$t('cubicMeters'),
        },
        {
          value: 'piece',
          text: this.$t('piece'),
        },
        {
          value: 'kilogram',
          text: this.$t('kilogram'),
        },
        {
          value: 'ton',
          text: this.$t('ton'),
        },
      ],
    }
  },
  methods: {
    ...mapActions('categories', ['addCategory']),
    validateForm() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          this.addCategory(this.category)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              this.$router.push({ name: 'categories' })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
